body {
  margin: 0;
  font-family: 'Expletus Sans', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e2e2e2;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.4;
}

.sans {
  font-family: 'Open Sans', sans-serif !important;
}

.serif {
  font-family: 'Cinzel', serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.formula {
  background-size: 20px 20px;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 1px, transparent 1px);
}

.center {
  text-align: center;
}

.mb-1 {
  margin-bottom: 1rem !important;
}
